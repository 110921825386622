import React from "react";
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  APP_PREFIX_SETTING_PATH,
} from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register/Register")
    ),
  },
  {
    key: 'forgot-password-profile',
    path: `${APP_PREFIX_PATH}/forgot-password/profile`,
    component: React.lazy(() => import('views/auth-views/authentication/forgot-password/ForgotPassword')),
    // meta: {
    //   settingLayout: true,
    // },
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password/ForgotPassword")
    ),
  },
];

const ekstockProtectedRoutes = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboards`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Dashboards")
    ),
  },
  {
    key: "products",
    path: `${APP_PREFIX_PATH}/products`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Product/list/Products")
    ),
  },
  {
    key: "products_trash",
    path: `${APP_PREFIX_PATH}/products/trash`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Product/trash/Products")
    ),
  },
  {
    key: "add-products",
    path: `${APP_PREFIX_PATH}/products/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Product/add/ProductAdd")
    ),
  },
  {
    key: "edit-products",
    path: `${APP_PREFIX_PATH}/products/edit-product/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Product/edit/EditProduct")
    ),
  },
  {
    key: "detail-products",
    path: `${APP_PREFIX_PATH}/products/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Product/DetailProduct")
    ),
  },
  {
    key: "categories",
    path: `${APP_PREFIX_PATH}/categories`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Category/list/Categories")
    ),
  },
  {
    key: "clients",
    path: `${APP_PREFIX_PATH}/clients`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Client/list/Clients")
    ),
  },
  {
    key: "supplier",
    path: `${APP_PREFIX_PATH}/suppliers`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Supplier/list/Suppliers")
    ),
  },
  {
    key: "collections",
    path: `${APP_PREFIX_PATH}/collections`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Collection/list/Collections")
    ),
  },
  {
    key: "collections_trash",
    path: `${APP_PREFIX_PATH}/collections/trash`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Collection/trash/Collections")
    ),
  },
  {
    key: "sales",
    path: `${APP_PREFIX_PATH}/sales`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/list/TransactionSale")
    ),
  },
  {
    key: "sale-create",
    path: `${APP_PREFIX_PATH}/sales/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/create/Sale/TransactionsSaleCreate")
    ),
  },
  {
    key: "sale-edit",
    path: `${APP_PREFIX_PATH}/sales/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/edit/Sale/TransactionsSaleEdit")
    ),
  },
  {
    key: "details-sales",
    path: `${APP_PREFIX_PATH}/sales/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/details/SalesDetails")
    ),
  },
  {
    key: "details-returns",
    path: `${APP_PREFIX_PATH}/returns/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/details/ReturnDetails")
    ),
  },
  {
    key: "purchese",
    path: `${APP_PREFIX_PATH}/purchases`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/list/TransactionsPurchase")
    ),
  },
  {
    key: "purchese-create",
    path: `${APP_PREFIX_PATH}/purchases/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/create/Purchase/TransactionsPurchaseCreate")
    ),
  },
  {
    key: "purchase-edit",
    path: `${APP_PREFIX_PATH}/purchases/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/edit/Purchase/TransactionsPurchaseEdit")
    ),
  },
  {
    key: "purchese-details",
    path: `${APP_PREFIX_PATH}/purchases/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/details/PurchesDetails")
    ),
  },
  {
    key: "depositIn-list",
    path: `${APP_PREFIX_PATH}/consignments_in`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/list/TransactionsDepotIn")
    ),
  },
  {
    key: "depositIn-create",
    path: `${APP_PREFIX_PATH}/consignments_in/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/create/DepotIn/TransactionsDepositInCreate")
    ),
  },
  {
    key: "depositIn-details",
    path: `${APP_PREFIX_PATH}/consignments_in/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/details/DepositInDetails")
    ),
  },
  {
    key: "depositIn-edit",
    path: `${APP_PREFIX_PATH}/consignments_in/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/edit/DepositIn/TransactionsDepositInEdit")
    ),
  },
  {
    key: "depositOut",
    path: `${APP_PREFIX_PATH}/consignments_out`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/list/TransactionsDepotOut")
    ),
  },
  {
    key: "depositOut-add",
    path: `${APP_PREFIX_PATH}/consignments_out/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/create/DepositOut/TransactionsDepositOutCreate")
    ),
  },
  {
    key: "depositout-details",
    path: `${APP_PREFIX_PATH}/consignments_out/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/details/DepositOutDetails")
    ),
  },
  {
    key: "depositOut-edit",
    path: `${APP_PREFIX_PATH}/consignments_out/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/edit/DepositOut/TransactionsDepositOutEdit")
    ),
  },
  {
    key: "MovementPreparation",
    path: `${APP_PREFIX_PATH}/preparations`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/list/MovementPreparation")
    ),
  },
  {
    key: "Movement-preparation-create",
    path: `${APP_PREFIX_PATH}/preparations/create`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/create/preparation/MovementPreparationCreate")
    ),
  },
  {
    key: "Movement-preparation-create-transaction",
    path: `${APP_PREFIX_PATH}/preparations/create/:idtx/:type`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/create/preparation/MovementPreparationCreate")
    ),
  },
  {
    key: "MovementPreparationDetails",
    path: `${APP_PREFIX_PATH}/preparations/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/details/preparation")
    ),
  },
  {
    key: "Movement",
    path: `${APP_PREFIX_PATH}/arrangements`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/list/MovementDeplacement")
    ),
  },
  {
    key: "Movement-create",
    path: `${APP_PREFIX_PATH}/arrangements/create`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/create/movement/MovementCreate")
    ),
  },
  {
    key: "MovementDetails",
    path: `${APP_PREFIX_PATH}/arrangements/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/details/deplacement")
    ),
  },
  {
    key: "MovementReceive",
    path: `${APP_PREFIX_PATH}/receptions`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/list/MovementReceive")
    ),
  },
  {
    key: "MovementReceiveDetails",
    path: `${APP_PREFIX_PATH}/receptions/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/details/receive")
    ),
  },
  {
    key: "Movement-receive-create",
    path: `${APP_PREFIX_PATH}/receptions/create`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/create/receive/MovementRecieveCreate")
    ),
  },
  {
    key: "Movement-receive-create-transaction",
    path: `${APP_PREFIX_PATH}/receptions/create/:idtx/:type`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Movement/create/receive/MovementRecieveCreate")
    ),
  },
  {
    key: "CollerctionAdd",
    path: `${APP_PREFIX_PATH}/collection/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Collection/CollectionAdd")
    ),
  },
  {
    key: "CollerctionDetails",
    path: `${APP_PREFIX_PATH}/collection/detail/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Collection/DetailCollection")
    ),
  },
  {
    key: "edit-collection",
    path: `${APP_PREFIX_PATH}/collection/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Collection/edit/EditCollection")
    ),
  },
  {
    key: "stores",
    path: `${APP_PREFIX_PATH}/stores`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Store/list/stores")
    ),
  },
  {
    key: "stores-details",
    path: `${APP_PREFIX_PATH}/stores/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Store/details/DetailStore")
    ),
  },
  {
    key: "stores-details-update-stock",
    path: `${APP_PREFIX_PATH}/stores/details/:id/update-stock`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Store/details/UpdateStockStore")
    ),
  },
  {
    key: "sections-details",
    path: `${APP_PREFIX_PATH}/sections/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Store/details/DetailSection")
    ),
  },
  {
    key: "sections-details-update-stock",
    path: `${APP_PREFIX_PATH}/sections/details/:id/update-stock`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Store/details/UpdateStockSection")
    ),
  },
  {
    key: "organisaion-edit",
    path: `${APP_PREFIX_PATH}/organisation/`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Organisation/OrganisationForm")
    ),
  },
  {
    key: "quotes",
    path: `${APP_PREFIX_PATH}/quotes/`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/quote/Quotes")
    ),
  },
  {
    key: "families",
    path: `${APP_PREFIX_PATH}/families/`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/FamilyProduct/list/list")
    ),
  },
  {
    key: "families-details",
    path: `${APP_PREFIX_PATH}/families/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/FamilyProduct/details/details")
    ),
  },
  { // encaissement interface
    key: "caisse",
    path: `${APP_PREFIX_PATH}/caisse/`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/create/cash/CashFlow")
    ),
  },

  {
    key: 'profile',
    path: `${APP_PREFIX_PATH}/profile`,
    component: React.lazy(() => import('views/app-views/pages/Lava/profile/Profile')),
    // meta: {
    //   settingLayout: true
    // }
  },
  // {
  //   key: 'editProfile',
  //   path: `${APP_PREFIX_PATH}/profile/editProfile`,
  //   component: React.lazy(() => import('views/app-views/pages/Lava/profile/EditProfile')),
  //   // meta: {
  //   //   settingLayout: true,
  //   // },
  // },
  {
    key: 'userSettings',
    path: `${APP_PREFIX_PATH}/userSettings`,
    component: React.lazy(() => import('views/app-views/pages/Lava/profile/Setting')),
    // meta: {
    //   settingLayout: true,
    // },
  },
  {
    key: 'userSettings',
    path: `${APP_PREFIX_PATH}/userSettings/*`,
    component: React.lazy(() => import('views/app-views/pages/Lava/profile/Setting')),
    // meta: {
    //   settingLayout: true,
    // },
  },
  {
    key: "return-products-sale",
    path: `${APP_PREFIX_PATH}/sale/:id/return-products`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/details/actions/sale/ReturnProducts")
    ),
  },
  {
    key: "return-products-purchase",
    path: `${APP_PREFIX_PATH}/purchase/:id/return-products`,
    component: React.lazy(() =>
      import("views/app-views/pages/ekstock/Transaction/details/actions/purchase/ReturnProducts")
    ),
  },
  {
    key: 'notifications',
    path: `${APP_PREFIX_SETTING_PATH}/notifications`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Notifications/Notifications')),
  },

];

const lavaProtectedRoutes = [
  {
    key: 'setting-dashboards',
    path: `${APP_PREFIX_SETTING_PATH}/dashboards`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Dashboards/Dashboards')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'register',
    path: `${APP_PREFIX_SETTING_PATH}/register`,
    component: React.lazy(() => import('views/auth-views/authentication/register/Register')),
    meta: {
      blankLayout: true
    }
  },
  {
    key: 'forgot-password',
    path: `${APP_PREFIX_SETTING_PATH}/forgot-password`,
    component: React.lazy(() => import('views/auth-views/authentication/forgot-password/ForgotPassword')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'profile',
    path: `${APP_PREFIX_SETTING_PATH}/profile`,
    component: React.lazy(() => import('views/app-views/pages/Lava/profile/Profile')),
    meta: {
      settingLayout: true
    }
  },
  {
    key: 'editProfile',
    path: `${APP_PREFIX_SETTING_PATH}/profile/editProfile`,
    component: React.lazy(() => import('views/app-views/pages/Lava/profile/EditProfile')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'users',
    path: `${APP_PREFIX_SETTING_PATH}/users`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Users/Users')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'user-update',
    path: `${APP_PREFIX_SETTING_PATH}/users/:userId/editUser`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Users/UserUpdate')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'add-user',
    path: `${APP_PREFIX_SETTING_PATH}/users/addUser`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Users/UserAdd')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'user-Details',
    path: `${APP_PREFIX_SETTING_PATH}/users/:userId`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Users/UserDetails')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'groups',
    path: `${APP_PREFIX_SETTING_PATH}/groups`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Groups/Groups')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'add-group',
    path: `${APP_PREFIX_SETTING_PATH}/groups/addGroup`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Groups/GroupAdd')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'update-group',
    path: `${APP_PREFIX_SETTING_PATH}/groups/:groupId/editGroup`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Groups/GroupUpdate')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'group-Details',
    path: `${APP_PREFIX_SETTING_PATH}/groups/:groupId`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Groups/GroupDetails')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'group-Details',
    path: `${APP_PREFIX_SETTING_PATH}/groups/groupDetails`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Groups/GroupDetails')),
    meta: {
      settingLayout: true,
    },
  },
  // {
  //   key: 'permissions',
  //   path: `${APP_PREFIX_SETTING_PATH}/permissions`,
  //   component: React.lazy(() => import('views/app-views/pages/Permissons/Permissions')),
  //   meta: {
  //     settingLayout: true,
  //   },
  // },
  {
    key: 'backups',
    path: `${APP_PREFIX_SETTING_PATH}/backups`,
    component: React.lazy(() => import('views/app-views/pages/Lava/Backup/Backups')),
    meta: {
      settingLayout: true,
    },
  },

  {
    key: 'activity_journal',
    path: `${APP_PREFIX_SETTING_PATH}/activity_journal`,
    component: React.lazy(() => import('views/app-views/pages/Lava/AcivityJournal/ActivityJournal')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'chat',
    path: `${APP_PREFIX_SETTING_PATH}/chat/*`,
    component: React.lazy(() => import('views/app-views/pages/Lava/chat/index')),
    meta: {
      settingLayout: true,
    },
  },
  {
    key: 'notFoundPage',
    path: `${APP_PREFIX_SETTING_PATH}/notFoundPage`,
    component: React.lazy(() => import('views/app-views/pages/NotFoundPage/NotFoundPage')),
  },
  {
    key: 'access-denied',
    path: `${APP_PREFIX_SETTING_PATH}/access-denied`,
    component: React.lazy(() => import('views/app-views/pages/NotFoundPage/403')),
  },
];

export const protectedRoutes = [
  ...ekstockProtectedRoutes,
  ...lavaProtectedRoutes,
];
